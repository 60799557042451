.Ellapseds .ms700 {
    color: #007700;
}

.Ellapseds .ms2800 {
    color: #EF8D00;
}

.Ellapseds .ms2800u {
    color: #AA0000;
}


.Ellapseds .card-header select {
    margin: -3px;
}

.Ellapseds td {
    font-size: 11px;
}