.Errors .ms700 {
    color: #007700;
}

.Errors .ms2800 {
    color: #EF8D00;
}

.Errors .ms2800u {
    color: #AA0000;
}


.Errors .card-header select {
    margin: -3px;
}

.Errors td {
    font-size: 11px;
}