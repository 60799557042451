.pointer {
    cursor: pointer;
}

.hide {
    display: none;
}



#unidade option {
    background-color: #ffffff;
    color:black;
}