.Login .login-panel {
    width: 500px !important;
}

.Login .form-check-input {
    width: 22px;
    height: 22px;
    margin-right: 8px;
}

.Login .form-check-label {
    margin-top: 4px;
}


.hide {
    display: none;
}