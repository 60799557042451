.Requests .ms700 {
    color: #007700;
}

.Requests .ms2800 {
    color: #EF8D00;
}

.Requests .ms2800u {
    color: #AA0000;
}


.Requests .card-header select {
    margin: -3px;
}